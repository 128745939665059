import { React, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Mobile from './mobile';
import Device from './device';
const Page = () => {
    const [step, setStep] = useState(1)
    const [balanceAmount, setBalanceAmount] = useState(0); // State to hold balance amount
    const [mobile, setMobile] = useState('');



    const handleNextStep = (e) => {
        setStep(step + 1)
    }

    const handlePrevStep = (e) => {
        setStep(step - 1)
    }

    const handlePaymentSubmit = (paymentData) => {
        console.log('Payment data submitted:', paymentData);
        alert('Payment data submitted successfully.');
    };

    return (
        <div className='container-fluid d-grid justify-content-center align-items-center mt-3 '>
            <div className='container-fluid  rounded border '>
                {step === 1 && <Mobile onNext={handleNextStep} setBalanceAmount={setBalanceAmount} setMobile={setMobile} mobile={mobile} />} {/* Pass setBalanceAmount function */}
                {step === 2 && <Device onPrev={handlePrevStep} onSubmit={handlePaymentSubmit} balanceAmount={balanceAmount} mobile={mobile} />} {/* Pass balanceAmount as prop */}
            </div>
        </div>

    )
}

export default Page