import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import url from '../routes/api';

const Device = ({ onPrev, onSubmit, balanceAmount, mobile }) => { // Use curly braces around props

    const [formData, setFormData] = useState({
        id: '',
        mobile_no: '',
        payment_rs: ''
    });
    const [responseData, setResponseData] = useState(null);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const [payment_status, setPayment_status] = useState(null);
    const [loading, setloading] = useState(false)
    const amount = Number(formData.payment_rs) + Number(balanceAmount);


    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setPayment_status('');
        setloading(true); // Set loading to true when form is submitted
        try {
            // Make a POST request to the API endpoint
            const response = await fetch(`${url}get_status?id=${formData.id}`);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            // Parse the JSON response
            const responseData = await response.json();
            setResponseData(responseData);
            setStatus(responseData.data.status);
            setError(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data');
        } finally {
            setloading(false); // Set loading to false after API call is completed
        }
    };

    // Update status to an empty string when ID changes
    useEffect(() => {
        setStatus('');
    }, [formData.id]);

    // Handle payment submission
    const handlePaymentSubmit = async (e) => {
        e.preventDefault();
        const change_status = "C";
    
        // Check if payment_rs is greater than or equal to 1
        if (formData.payment_rs < 1) {
            alert('Payment amount must be at least 1.');
            return;
        }
    
        try {
            // Prepare data to be sent in the request body
            const requestData = {
                id: formData.id,
                rs_paid: amount,
                mob_no: mobile,
                status: change_status
            };
    
            // Make a POST request to the payment API endpoint
            const response = await fetch(`${url}data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit payment data');
            }
    
            // Parse the JSON response
            const responseData = await response.json();
            console.log('Payment data submitted successfully:', responseData);
            setPayment_status('Payment data submitted successfully');
    
            // setStatus('P')
    
        } catch (error) {
            console.error('Error submitting payment data:', error);
            setPayment_status('Error submitting payment data', error.message);
        }
    };
    

    return (
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center mt-3">
            {balanceAmount !== null && (
                <div className="mt-3 d-flex align-self-end">
                
                    <p>Bal Rs:  <strong> ₹{balanceAmount}</strong></p>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="mb-2">
                    <h5 className='mt-3'>Device Id</h5>
                    <input
                        type="text"
                        className="form-control"
                        id="id"
                        name="id"
                        value={formData.id}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="text-center p-2">
                    <button type="submit " className="btn btn-primary">Check</button>
                </div>
            </form>
            {loading && <div class="spinner-border text-dark p-2 mb-2"></div>}
            {error && <div>Error: {error}</div>}
            {status &&
                <div className="mt-2 d-flex align-self-center flex-column">
                    <p className='align-self-center'>Status: <strong>{status}</strong></p>
                    {['C'].includes(status) && <p className='bg-danger text-white rounded '>Please Select another Device</p>}
                    {['I', 'O', 'E'].includes(status) && <p className='text-success rounded '>Proceed To Payment</p>}
                    {['P'].includes(status) && <p className='bg-success text-white rounded  '>Payment data submitted successfully</p>}
                </div>
            }

            {['I', 'O', 'E'].includes(status) && (
                <form onSubmit={handlePaymentSubmit}>
                    <div className="mb-2">
                        <label htmlFor="payment_rs" className="form-label">Payment Rs:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="payment_rs"
                            name="payment_rs"
                            value={formData.payment_rs}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className='border p-2 rounded mb-2'>
                        <div className='d-flex justify-content-between'>
                            <p>Bal Amount</p>
                            <p>₹{balanceAmount}</p>
                        </div>
                        <div className='d-flex justify-content-between p-0 m-0'>
                            <p>Paid</p>
                            <p>₹{formData.payment_rs}</p>
                        </div>
                        <hr></hr>
                        <div className='d-flex justify-content-between p-0 m-0'>
                            <p>Total Amount</p>
                            <p>₹{amount}</p>
                        </div>
                    </div>
                    <div className="text-center mb-2">
                        <button type="submit" className="btn btn-primary">Pay</button>
                    </div>

                </form>
            )}
            {payment_status && <div className='p-2'><strong>{payment_status}</strong></div>}
        </div>
    )
}

export default Device;
