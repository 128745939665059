import logo from './logo.svg';
import './App.css';
import Home1 from './components/home1';
import Home3 from './components/home3';
import Home2 from './components/home2';
import Home from './components/home';
import Main from './components/main';
import Mobile from './components/mobile';
import Page from './components/page';
function App() {
  return (
    <div >
      {/* <Home/> */}

      <Page/>
    </div>
  );
}

export default App;
