import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import url from '../routes/api';
const Mobile = ({ onNext, setBalanceAmount, setMobile, mobile }) => {
    // const [mobile, setMobile] = useState('');
    const [isValid, setIsValid] = useState(true);
    // const [balanceAmount, setBalanceAmount] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        setMobile(e.target.value);
        setIsValid(e.target.validity.valid);
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValid) {
            try {
                // Make API request to reset balance
                const response = await fetch(`${url}reset_balance/${mobile}`);
                if (!response.ok) {
                    throw new Error('Failed to reset balance');
                }
                const data = await response.json();
                onNext(mobile);
                setBalanceAmount(data.balance_amount);

            } catch (error) {
                console.error('Error resetting balance:', error);
                // Handle error
            }
        }
    };

    return (
        <div className='container-fluid d-flex justify-content-center align-items-center'>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <h5 className='mt-3'>Mobile No.</h5>
                        <input
                            type="tel"
                            className={`form-control ${isValid ? '' : 'is-invalid'}`}
                            id="mobile"
                            name="mobile"
                            value={mobile}
                            onChange={handleInputChange}
                            required
                            pattern="[0-9]{10}"
                        />
                        <div className="invalid-feedback">
                            Please enter a valid 10-digit mobile number.
                        </div>
                        {errorMessage && <div className="alert alert-danger mt-3" role="alert">{errorMessage}</div>}

                    </div>

                    <div className="text-center mb-3"> {/* Center the button */}
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Mobile;
