import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import BASE_URL from './api';

const Home1 = () => {
    const getCurrentDateTimeString = () => {
        const currentDateTime = new Date();
        const year = currentDateTime.getFullYear();
        const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
        const day = String(currentDateTime.getDate()).padStart(2, '0');
        const hours = String(currentDateTime.getHours()).padStart(2, '0');
        const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
        const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');
      
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
      };
      

      const [formData, setFormData] = useState({
        datetime: getCurrentDateTimeString(),
        amount: '',
        mobile: '',
        // id: Math.floor(Math.random() * 1000), // Generate a random number for ID 
      });
    
      const [submitMessage, setSubmitMessage] = useState(null);
    
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Generate a new ID every time the amount is entered
      const newId = Math.floor(Math.random() * 1000); 
    
        setFormData({
          ...formData,
          [name]: value,
          id: newId,
        });
      };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append('datetime', formData.datetime);
    form.append('amount', formData.amount);
    form.append('mobile', formData.mobile);
    form.append('id', formData.id);

    try {
        const response = await fetch(`${BASE_URL}/save-data1`, {
          method: 'POST',
          body: form,
        });
  
        if (response.ok) {
          setSubmitMessage('Data saved successfully');
        } else {
          setSubmitMessage('Failed to save data');
        }
      } catch (error) {
        console.error('Error:', error);
        setSubmitMessage('An error occurred while saving data');
      }
      // Clear the message after 3 seconds
    setTimeout(() => {
      setSubmitMessage(null);
    }, 3000);
    };

    return (
        <div className="container mt-5 d-flex justify-content-center align-items-center ">
          <div className="text-center">
            <h1>Payment Gateway 1</h1>
            <form onSubmit={handleSubmit}>
              
              <div className="mb-3">
                <label className="form-label">
                  Amount(₹):
                  <input
                    type="text"
                    className="form-control"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
              <div className="mb-3">
                        <label className="form-label">
                            Mobile Number:
                            <input
                                type="text"
                                className="form-control"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleInputChange}
                                required
                                pattern="[0-9]*"
                                title="Please enter Mobile No. only"
                            />
                        </label>
                    </div>
              
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            {submitMessage && (
              <div className={`alert ${submitMessage.includes('successfully') ? 'alert-success' : 'alert-danger'} mt-3`}>
                {submitMessage}
              </div>
            )}
          </div>
        </div>
      );
    };

export default Home1;
